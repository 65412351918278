































































import { Component, Prop, Vue } from 'vue-property-decorator';
import UpdateModalServicesRow from '@/components/UpdateModalServicesRow.vue';
import { ServiceData } from '@/components/UpdateModal.vue';
import Vehicle from '@/interfaces/Vehicle';
import UpdateModalOverviewRow from '@/components/UpdateModalOverviewRow.vue';
import InstalledService from '@/interfaces/InstalledService';
import SchedulePost from '@/interfaces/SchedulePost';
import { Action, Getter, State } from 'vuex-class';
import Image from '@/interfaces/Image';
import Tag from '@/interfaces/Tag';
import { format, parseISO, startOfTomorrow } from 'date-fns';

@Component({
    components: {
        UpdateModalOverviewRow,
        UpdateModalServicesRow,
    },
})
export default class UpdateModalOverview extends Vue {
    @Prop({ required: true })
    serviceData!: ServiceData[];

    @Prop({ required: true })
    vehicles!: Vehicle[];

    @State('selectedVehicleTypeId')
    vehicleTypeId!: number;

    @Getter('imageById')
    imageById!: (id: number) => Image;

    @Getter('tagById')
    tagById!: (id: number) => Tag;

    @Action('addSchedule')
    addSchedule!: (payload: SchedulePost) => Promise<void>;

    loading = false;

    schedule = false;

    scheduleAt = format(startOfTomorrow(), 'yyyy-MM-dd\'T\'HH:mm');

    async submitForm(): Promise<void> {
        const valid = (this.$refs.form as HTMLFormElement).reportValidity();

        if (valid) {
            await (this.schedule ? this.submitSchedule() : this.submitImmediate());
        }
    }

    async submitSchedule(): Promise<void> {
        await this.submit(parseISO(this.scheduleAt));
    }

    async submitImmediate(): Promise<void> {
        return this.submit(new Date());
    }

    private async submit(date: Date): Promise<void> {
        this.loading = true;
        const services = this.serviceData
            .filter(service => service.enabled)
            .map(data => {
                const image = this.imageById(Number(data.image));

                if (!image) {
                    return {
                        ...data.service,
                        image: null,
                    };
                }

                delete image.tags;
                return {
                    ...data.service,
                    image,
                    version: {
                        tag: this.tagById(Number(data.tag)),
                        run_template_id: Number(data.runTemplate),
                        build_template_id: Number(data.buildTemplate),
                    },
                } as InstalledService;
            });

        const schedule = {
            schedule_at: date.toISOString(),
            services,
            vehicle_ids: this.vehicles.map(vehicle => vehicle.id),
            vehicle_type_id: this.vehicleTypeId,
        };

        try {
            await this.addSchedule(schedule as SchedulePost);
        } catch (err) {
            this.loading = false;

            return;
        }

        this.$emit('submit');
    }
}

