













import { Component, Vue } from 'vue-property-decorator';
import { LelyLoginView } from '@tec/frontend-vue-shared';
import store from '@/store';

@Component({
    components: { LelyLoginView },
})
export default class LoginView extends Vue {
    async login(): Promise<void> {
        await store.dispatch('startSync');

        await this.$router.push({
            name: 'vehicles',
        });
    }
}

