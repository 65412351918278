








import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import UpdateModalServiceRow from '@/components/UpdateModalServicesRow.vue';
import UpdateModalServices from '@/components/UpdateModalServices.vue';
import Service from '@/interfaces/Service';
import Vehicle from '@/interfaces/Vehicle';
import { Action, Getter } from 'vuex-class';
import UpdateModalOverview from '@/components/UpdateModalOverview.vue';

export interface ServiceData {
    service: Service,
    enabled: boolean,
    image: number | null,
    tag: number | null,
    runTemplate: number | null,
    buildTemplate: number | null,
    serviceInstalled: boolean,
}

@Component({
    components: {
        UpdateModalOverview,
        UpdateModalServices,
        UpdateModalServiceRow,
    },
})
export default class UpdateModal extends Vue {
    @Prop()
    vehicles!: Vehicle[];

    @Getter('selectedVehicleTypeServices')
    services!: Service[];

    @Action('syncTags')
    syncTags!: (imageId: number) => Promise<void>;

    @Action('fetchTemplateContent')
    fetchTemplateContent!: (id: number) => Promise<void>;

    step = 1;

    serviceData: ServiceData[] | null = null;

    @Watch('services', { immediate: true })
    updateServices(): void {
        if (this.serviceData !== null) {
            return;
        }

        this.serviceData = this.services.map(service => {
            const image = this.findUniqueImageIdForService(service.id);

            if (image) {
                this.syncTags(image);
            }

            if (service.last_used_build_template_id) {
                this.fetchTemplateContent(service.last_used_build_template_id);
            }

            if (service.last_used_run_template_id) {
                this.fetchTemplateContent(service.last_used_run_template_id);
            }

            return {
                service,
                image,
                enabled: false,
                tag: null,
                runTemplate: service.last_used_run_template_id,
                buildTemplate: service.last_used_build_template_id,
                serviceInstalled: !!image,
            };
        });
    }

    findUniqueImageIdForService(serviceId: number): number | null {
        const uniqueUsedImageIds = [...new Set(this.vehicles.map(vehicle => vehicle.services.find(service => service.id === serviceId)?.image?.id))];

        if (uniqueUsedImageIds.length === 1) {
            return uniqueUsedImageIds[0] || null;
        }

        return null;
    }

    resetItem(serviceId: number): void {
        if (!this.serviceData) {
            return;
        }

        const idx = this.serviceData?.findIndex(item => item.service.id === serviceId);

        if (idx === -1) {
            return;
        }

        const service = this.services.find(service => service.id === serviceId);

        if (!service) {
            return;
        }

        const image = this.findUniqueImageIdForService(serviceId);

        Vue.set(this.serviceData, idx, {
            service,
            image,
            enabled: false,
            tag: null,
            runTemplate: service.last_used_run_template_id,
            buildTemplate: service.last_used_build_template_id,
            serviceInstalled: !!image,
        });
    }
}

