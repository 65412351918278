


















import { Component, Vue } from 'vue-property-decorator';
import SettingsServiceRow from '@/components/SettingsServiceRow.vue';
import { Action, Getter } from 'vuex-class';
import ServiceForm from '@/components/ServiceForm.vue';
import Image from '@/interfaces/Image';
import SettingsImageRow from '@/components/SettingsImageRow.vue';

@Component({
    components: {
        SettingsImageRow,
        ServiceForm,
        SettingsServiceRow,
    },
})
export default class SettingsImagesView extends Vue {
    @Getter('images')
    images!: Image[];

    @Action('deleteImage')
    deleteImage!: (image: Image) => Promise<void>;
}

