























import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import VehicleType from '@/interfaces/VehicleType';
import { BIconCamera } from 'bootstrap-vue';
import { LelyApp, LelyAuth } from '@tec/frontend-vue-shared';

@Component({
    components: {
        BIconCamera,
    },
})
export default class App extends Vue {
    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    @State('errors')
    errors!: string[];

    @Mutation('resetErrors')
    resetErrors!: () => void;

    get loginPage(): boolean {
        return this.$route.name === 'login';
    }

    get vehiclesPage(): boolean {
        return this.$route.name === 'vehicles';
    }

    logout(): void {
        LelyAuth.logout();

        this.$router.push('login');
    }

    get selectedVehicleTypeId(): number {
        return this.$store.state.selectedVehicleTypeId;
    }

    set selectedVehicleTypeId(id: number) {
        this.$store.commit('setSelectedVehicleTypeId', id);
    }

    get app(): LelyApp {
        return LelyApp.HMS;
    }

    @Watch('errors')
    async showErrors(): Promise<void> {
        if (this.errors.length === 0) {
            return;
        }

        const messages = this.errors.map(err => this.$createElement('div', { class: 'alert alert-danger' }, err));

        this.resetErrors();

        await this.$bvModal.msgBoxOk(messages, {
            title: 'Errors',
            okTitle: 'Close',
            okVariant: 'primary',
        });
    }
}

