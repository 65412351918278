







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class UpdateModalServicesRow extends Vue {
    @Prop({ type: Boolean })
    scheduled!: boolean;
}

