































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Vehicle from '@/interfaces/Vehicle';
import VehicleTableRow from '@/components/VehicleTableRow.vue';
import Service from '@/interfaces/Service';
import UpdateModal from '@/components/UpdateModal.vue';
import SchedulePlanned from '@/interfaces/SchedulePlanned';

@Component({
    components: {
        UpdateModal,
        VehicleTableRow,
    },
})
export default class VehiclesView extends Vue {
    @Getter('selectedVehicleTypeVehicles')
    vehicles!: Vehicle[];

    @Getter('selectedVehicleTypeServices')
    services!: Service[];

    @Getter('selectedVehicleTypeSchedules')
    schedules!: SchedulePlanned[];

    @Action('cancelSchedule')
    cancelSchedule!: (id: number) => Promise<void>;

    @Action('syncSchedule')
    syncSchedule!: () => Promise<void>;

    selected: number[] = [];

    get selectedVehicles(): Vehicle[] {
        return this.selected.map(id => this.vehicles.find(vehicle => vehicle.id === id) as Vehicle);
    }

    get tableData(): { id: number, vehicle: Vehicle, schedule?: SchedulePlanned }[] {
        return this.vehicles.map(vehicle => ({
            id: vehicle.id,
            vehicle,
            schedule: this.schedules.find(schedule => schedule.vehicle_id === vehicle.id),
        }));
    }

    @Watch('vehicles')
    reset(): void {
        this.selected = [];
    }

    async cancelAll(): Promise<void> {
        if (!await this.$bvModal.msgBoxConfirm('Are you sure you want to cancel updating all vehicles?', {
            title: 'Remove scheduled updates',
            size: 'sm',
        })) {
            return;
        }

        await Promise.all(this.schedules.map(schedule => this.cancelSchedule(schedule.id)));
        await this.syncSchedule();
    }
}

