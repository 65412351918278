










































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ServiceData } from '@/components/UpdateModal.vue';
import Vehicle from '@/interfaces/Vehicle';
import Image from '@/interfaces/Image';
import Tag from '@/interfaces/Tag';
import { Getter } from 'vuex-class';
import Service from '@/interfaces/Service';
import ImageLabel from '@/components/ImageLabel.vue';

@Component({
    components: { ImageLabel },
})
export default class UpdateModalOverviewRow extends Vue {
    @Prop({ required: true })
    serviceData!: ServiceData[];

    @Prop({ required: true })
    vehicle!: Vehicle;

    @Getter('imageById')
    imageById!: (id: number) => Image;

    @Getter('tagById')
    tagById!: (id: number) => Tag;

    get enabledServices(): ServiceData[] {
        return this.serviceData.filter(data => data.enabled);
    }

    get changes(): {
        service: Service,
        from: { image?: Image, tag?: Tag },
        to: { image?: Image, tag?: Tag } | null,
    }[] {
        return this.enabledServices.map(serviceData => ({
            service: serviceData.service,
            from: {
                image: this.vehicle.services.find(installedService => installedService.id === serviceData.service.id)?.image,
                tag: this.vehicle.services.find(installedService => installedService.id === serviceData.service.id)?.version?.tag,
            },
            to: serviceData.image
                ? {
                    image: this.imageById(Number(serviceData.image)),
                    tag: this.tagById(Number(serviceData.tag)),
                } : null,
        }));
    }
}

