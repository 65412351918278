import { ActionContext } from 'vuex';
import RootState from '@/store/rootState';
import axios from 'axios';
import SchedulePost from '@/interfaces/SchedulePost';
import Service from '@/interfaces/Service';
import Tag from '@/interfaces/Tag';
import Image from '@/interfaces/Image';

// const syncInterval = 1000 * 5;
// const syncInstance = 0;

export const startSync = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    await context.dispatch('sync');

    // if (syncInstance) {
    //     window.clearInterval(syncInstance);
    // }
    //
    // syncInstance = window.setInterval(() => {
    //     context.dispatch('sync');
    // }, syncInterval);
};

export const sync = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    await Promise.all([
        context.dispatch('syncImages'),
        context.dispatch('syncVehicles'),
        context.dispatch('syncVehicleTypes'),
        context.dispatch('syncSchedule'),
        context.dispatch('syncServices'),
        context.dispatch('syncCameras'),
    ]);
};

export const syncImages = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('images');
    context.commit('setImages', res.data);

    res.data.forEach((image: Image) => context.commit('setTags', {
        imageId: image.id,
        data: image.tags,
    }));
};

export const syncVehicles = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('vehicles');
    context.commit('setVehicles', res.data);
};

export const syncVehicleTypes = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('vehicle-types');
    context.commit('setVehicleTypes', res.data);
};

export const syncSchedule = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('schedule');
    context.commit('setSchedule', res.data);
};

export const syncServices = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('services');
    context.commit('setServices', res.data);
};

export const syncVersions = async(context: ActionContext<RootState, RootState>, serviceId: number): Promise<void> => {
    const res = await axios.get(`services/${serviceId}/versions`);
    context.commit('setVersions', {
        serviceId,
        data: res.data,
    });
};

export const syncTags = async(context: ActionContext<RootState, RootState>, imageId: number): Promise<void> => {
    const res = await axios.get(`images/${imageId}/tags`);
    context.commit('setTags', {
        imageId,
        data: res.data,
    });
};

export const addSchedule = async(context: ActionContext<RootState, RootState>, payload: SchedulePost): Promise<void> => {
    await axios.post('schedule', payload);

    return context.dispatch('syncSchedule');
};

export const cancelSchedule = async(context: ActionContext<RootState, RootState>, scheduleId: number): Promise<void> => axios.delete(`schedule/${scheduleId}`);

export const fetchTemplateContent = async(context: ActionContext<RootState, RootState>, id: number): Promise<void> => {
    const res = await axios.get(`templates/${id}`);

    context.commit('setTemplateContent', {
        id,
        data: res.data,
    });
};

export const addTemplate = async(context: ActionContext<RootState, RootState>, value: string): Promise<number> => {
    const res = await axios.post('templates', value);

    await context.dispatch('fetchTemplateContent', res.data.id);

    return res.data.id;
};

export const deleteService = async(context: ActionContext<RootState, RootState>, serviceId: number): Promise<void> => {
    await axios.delete(`services/${serviceId}`);

    await context.dispatch('syncServices');
};

export const deleteImage = async(context: ActionContext<RootState, RootState>, imageId: number): Promise<void> => {
    await axios.delete(`images/${imageId}`);

    await context.dispatch('syncImages');
};

export const deleteTag = async(context: ActionContext<RootState, RootState>, tag: Tag): Promise<void> => {
    await axios.delete(`images/${tag.image_id}/tags/${tag.id}`);

    await context.dispatch('syncTags', tag.image_id);
};

export const saveService = async(context: ActionContext<RootState, RootState>, service: Partial<Service>): Promise<void> => {
    if (service.id) {
        await axios.put(`services/${service.id}`, service);
    } else {
        await axios.post('services', service);
    }

    await context.dispatch('syncServices');
};

export const syncCameras = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get(`${process.env.VUE_APP_HMF_API_URL}/install/cameras`);
    context.commit('setCameras', res.data);
};
