



























import { Component, Prop, Vue } from 'vue-property-decorator';
import VehicleType from '@/interfaces/VehicleType';
import { Action, Getter } from 'vuex-class';
import Service from '@/interfaces/Service';
import ServiceForm from '@/components/ServiceForm.vue';

@Component({
    components: {
        ServiceForm,
    },
})
export default class SettingsServiceRow extends Vue {
    @Prop({ required: true })
    service!: Service;

    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    @Action('deleteService')
    deleteServiceAction!: (id: number) => Promise<void>;

    @Action('saveService')
    saveService!: (service: Service) => Promise<void>;

    get modalId(): string {
        return `edit-service-modal-${this.service.id}`;
    }

    get vehicleType(): string {
        if (!this.service.vehicle_type_id) {
            return '';
        }

        return this.vehicleTypeById(this.service.vehicle_type_id)?.name || '';
    }

    async deleteService(): Promise<void> {
        const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the service "${this.service.name}"?`, {
            title: 'Delete service',
            size: 'sm',
        });

        if (!confirm) {
            return;
        }

        await this.deleteServiceAction(this.service.id);
    }
}

