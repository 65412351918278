
































import { Component, Vue } from 'vue-property-decorator';
import SettingsServiceRow from '@/components/SettingsServiceRow.vue';
import { Action, Getter } from 'vuex-class';
import Service from '@/interfaces/Service';
import ServiceForm from '@/components/ServiceForm.vue';

@Component({
    components: {
        ServiceForm,
        SettingsServiceRow,
    },
})
export default class SettingsServicesView extends Vue {
    @Getter('services')
    services!: Service[];

    @Action('saveService')
    saveService!: (service: Service) => Promise<void>;
}

