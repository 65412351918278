






































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Vehicle from '@/interfaces/Vehicle';
import VehicleType from '@/interfaces/VehicleType';
import { Action, Getter } from 'vuex-class';
import Service from '@/interfaces/Service';
import InstalledService from '@/interfaces/InstalledService';
import SchedulePlanned from '@/interfaces/SchedulePlanned';
import { format, parseISO } from 'date-fns';
import ImageLabel from '@/components/ImageLabel.vue';

@Component({
    components: { ImageLabel },
})
export default class VehicleTableRow extends Vue {
    @Prop({ required: true })
    vehicle!: Vehicle;

    @Prop({ required: true })
    services!: Service[];

    @Prop({
        default: false,
        type: Boolean,
    })
    checked!: boolean;

    @Prop({ default: null })
    schedule!: SchedulePlanned | null;

    @Action('cancelSchedule')
    cancelSchedule!: (id: number) => Promise<void>;

    @Action('syncSchedule')
    syncSchedule!: () => Promise<void>;

    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    cancelLoading = false;

    async cancelUpdate(): Promise<void> {
        if (!this.schedule) {
            return;
        }

        if (!await this.$bvModal.msgBoxConfirm(`Are you sure you want to cancel updating ${this.vehicle.name}?`, {
            title: 'Remove scheduled update',
            size: 'sm',
        })) {
            return;
        }

        this.cancelLoading = true;

        try {
            await this.cancelSchedule(this.schedule.id);
            await this.syncSchedule();
        } catch (err) {
            // noop
        } finally {
            this.cancelLoading = false;
        }
    }

    get vehicleServices(): { service: Service, current?: InstalledService, scheduled?: InstalledService }[] {
        return this.services
            .map(service => ({
                service,
                current: this.vehicle.services.find(installedService => installedService.id === service.id),
                scheduled: this.schedule?.services.find(scheduledService => scheduledService.id === service.id),
            }))
            .filter(service => service.current || service.scheduled);
    }

    get scheduledAt(): string {
        if (!this.schedule) {
            return '';
        }

        return format(parseISO(this.schedule.schedule_at), 'dd-MM-yyyy HH:mm');
    }

    get lastUpdated(): string {
        if (!this.vehicle.last_updated_at) {
            return '    ';
        }

        return format(parseISO(this.vehicle.last_updated_at), 'dd MMM yyyy HH:mm');
    }
}

