


































































import {
    Component, Prop, PropSync, Vue, Watch,
} from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import Service from '@/interfaces/Service';
import Image from '@/interfaces/Image';
import Tag from '@/interfaces/Tag';
import TemplateEditorModal from '@/components/TemplateEditorModal.vue';

@Component({
    components: { TemplateEditorModal },
})
export default class UpdateModalServicesRow extends Vue {
    @Prop()
    service!: Service;

    @Prop({ type: Boolean })
    serviceInstalled!: boolean;

    @Getter('images')
    images!: Image[];

    @Getter('tagsByImageId')
    tagsByImageId!: (imageId: number) => Tag[];

    @Getter('templateContentById')
    templateContentById!: (id: number) => string;

    @Action('addTemplate')
    addTemplate!: (content: string) => Promise<number>;

    @PropSync('enabled')
    syncedEnabled!: boolean;

    @PropSync('image')
    syncedImage!: number | null;

    @PropSync('tag')
    syncedTag!: number | null;

    @PropSync('buildTemplate')
    syncedBuildTemplate!: number | null;

    @PropSync('runTemplate')
    syncedRunTemplate!: number | null;

    buildTemplateContent = '';

    runTemplateContent = '';

    @Watch('syncedBuildTemplateContent', { immediate: true })
    resetBuildTemplateContent(): void {
        this.buildTemplateContent = this.syncedBuildTemplateContent;
    }

    @Watch('syncedRunTemplateContent', { immediate: true })
    resetRunTemplateContent(): void {
        this.runTemplateContent = this.syncedRunTemplateContent;
    }

    get syncedBuildTemplateContent(): string {
        return this.syncedBuildTemplate
            ? this.templateContentById(this.syncedBuildTemplate) || '' : '';
    }

    get syncedRunTemplateContent(): string {
        return this.syncedRunTemplate
            ? this.templateContentById(this.syncedRunTemplate) || '' : '';
    }

    async saveBuildTemplateContent(): Promise<void> {
        if (this.buildTemplateContent !== this.syncedBuildTemplateContent) {
            this.syncedBuildTemplate = await this.addTemplate(this.buildTemplateContent);
        }
    }

    async saveRunTemplateContent(): Promise<void> {
        if (this.runTemplateContent !== this.syncedRunTemplateContent) {
            this.syncedRunTemplate = await this.addTemplate(this.runTemplateContent);
        }
    }

    get tags(): Tag[] {
        if (!this.syncedImage) {
            return [];
        }

        return this.tagsByImageId(this.syncedImage);
    }

    get imageState(): boolean | undefined {
        return (this.syncedEnabled && this.syncedImage === null && !this.serviceInstalled) ? false : undefined;
    }

    get tagState(): boolean | undefined {
        return (this.syncedEnabled && this.syncedImage !== null && !this.syncedTag) ? false : undefined;
    }
}

