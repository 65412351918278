










import { Component, Prop, Vue } from 'vue-property-decorator';
import { PrismEditor } from 'vue-prism-editor';
import Prism from 'prismjs';

import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/themes/prism-tomorrow.css';

@Component({
    components: {
        PrismEditor,
    },
})
export default class TemplateEditorModal extends Vue {
    @Prop({ type: String })
    value!: string;

    @Prop({
        required: true,
        type: String,
    })
    type!: 'build' | 'run';

    get grammar(): Prism.Grammar {
        return this.type === 'build' ? Prism.languages.docker : Prism.languages.json;
    }

    get language(): string {
        return this.type === 'build' ? 'docker' : 'json';
    }

    get calculatedValue(): string {
        return this.value;
    }

    set calculatedValue(value: string) {
        this.$emit('input', value);
    }

    highlighter(code: string): string {
        return Prism.highlight(code, this.grammar, this.language);
    }
}

