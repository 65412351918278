import RootState from '@/store/rootState';
import Image from '@/interfaces/Image';
import Vehicle from '@/interfaces/Vehicle';
import VehicleType from '@/interfaces/VehicleType';
import Service from '@/interfaces/Service';
import Version from '@/interfaces/Version';
import Tag from '@/interfaces/Tag';
import SchedulePlanned from '@/interfaces/SchedulePlanned';
import Vue from 'vue';

export const setImages = (state: RootState, payload: Image[]): void => {
    state.images = payload;
};

export const setVehicles = (state: RootState, payload: Vehicle[]): void => {
    state.vehicles = payload;
};

export const setVehicleTypes = (state: RootState, payload: VehicleType[]): void => {
    state.vehicleTypes = payload;

    if (!state.selectedVehicleTypeId && state.vehicleTypes.length) {
        state.selectedVehicleTypeId = state.vehicleTypes[0].id;
    }
};

export const setSchedule = (state: RootState, payload: SchedulePlanned[]): void => {
    state.schedules = payload;
};

export const setServices = (state: RootState, payload: Service[]): void => {
    state.services = payload;
};

export const setTemplateContent = (state: RootState, payload: { id: number, data: string }): void => {
    Vue.set(state.templateContent, payload.id, payload.data);
};

export const setSelectedVehicleTypeId = (state: RootState, payload: number): void => {
    state.selectedVehicleTypeId = payload;
};

export const setVersions = (state: RootState, payload: { serviceId: number, data: Version[] }): void => {
    state.versions = [...state.versions.filter(version => version.service_id !== payload.serviceId), ...payload.data];
};

export const setTags = (state: RootState, payload: { imageId: number, data: Tag[] }): void => {
    state.tags = [...state.tags.filter(tag => tag.image_id !== payload.imageId), ...payload.data];
};

export const setCameras = (state: RootState, payload: string[]): void => {
    state.cameras = payload;
};

export const addError = (state: RootState, payload: string): void => {
    state.errors.push(payload);
};

export const resetErrors = (state: RootState): void => {
    state.errors = [];
};
