







































import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { LelyTableActionsConfig, LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import axios from 'axios';
import FileSaver from 'file-saver';

@Component({
    components: {},
})
export default class CamerasView extends Vue {
    @Getter('cameras')
    cameras!: string[];

    newSerialId = '';

    get tableItems(): { serialId: string }[] {
        return this.cameras.map(camera => ({
            serialId: camera,
        }));
    }

    get columns(): LelyTableColumnsConfig<{ serialId: string }> {
        return [
            {
                header: 'Serial ID',
                value: 'serialId',
            },
        ];
    }

    get actions(): LelyTableActionsConfig<{ serialId: string }> {
        return [
            {
                name: 'download',
                label: 'Download',
                icon: 'lely-icon-download',
            },
            {
                name: 'upload',
                label: 'Upload',
                icon: 'lely-icon-upload',
            },
        ];
    }

    async addCamera(serialId: string): Promise<void> {
        if (!(this.$refs.addCameraForm as HTMLFormElement).reportValidity()) {
            return;
        }

        try {
            await axios.post(`${process.env.VUE_APP_HMF_API_URL}/install/cameras`, {
                serial_id: serialId,
            });
        } catch (error: any) {
            if (error.response.status === 409) {
                await this.$bvModal.msgBoxOk(`${serialId} already exists`, {
                    size: 'sm',
                });
            }

            return;
        }

        await this.upload(serialId);
        await this.$store.dispatch('syncCameras');

        this.$bvModal.hide('add-camera');
    }

    cameraUrl(serialId: string): string {
        return `${process.env.VUE_APP_HMF_API_URL}/install/cameras/${serialId}`;
    }

    async download(serialId: string): Promise<void> {
        const response = await axios.get(this.cameraUrl(serialId), {
            responseType: 'blob',
        });

        if (response.status !== 200) {
            return this.$bvModal.msgBoxOk(`Settings not found for ${serialId}`, {
                size: 'sm',
            });
        }

        console.log('IN', response.data);

        return FileSaver.saveAs(response.data, `${serialId}.zip`);
    }

    async upload(serialId: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = '.zip';
            input.click();

            input.onchange = async() => {
                if (!input.files || !input.files.length) {
                    reject();

                    return;
                }

                const file = input.files[0];
                const formData = new FormData();
                formData.set('file', file);

                await axios.put(this.cameraUrl(serialId), formData);

                resolve();
            };
        });
    }
}

