import RootState from '@/store/rootState';

export default {
    images: [],
    schedules: [],
    services: [],
    versions: [],
    templateContent: {},
    vehicles: [],
    vehicleTypes: [],
    tags: [],
    selectedVehicleTypeId: null,
    errors: [],
    cameras: [],
} as RootState;
