





















import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import Service from '@/interfaces/Service';
import { Getter } from 'vuex-class';
import VehicleType from '@/interfaces/VehicleType';

@Component({
    components: {},
})
export default class ServiceForm extends Vue {
    @Prop()
    service!: Service;

    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    name = '';

    vehicleTypeId: number | null = null;

    @Watch('service', { immediate: true })
    setService() {
        if (!this.service) {
            return;
        }

        this.name = this.service.name;
        this.vehicleTypeId = this.service.vehicle_type_id;
    }

    submit(): void {
        if (!(this.$refs.form as HTMLFormElement).reportValidity()) {
            return;
        }

        this.$emit('submit', {
            id: this.service ? this.service.id : undefined,
            name: this.name,
            vehicle_type_id: this.vehicleTypeId,
        } as Partial<Service>);
    }
}

