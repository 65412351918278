import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VehiclesView from '@/views/VehiclesView.vue';
import LoginView from '@/views/LoginView.vue';
import SettingsView from '@/views/SettingsView.vue';
import SettingsServicesView from '@/views/Settings/SettingsServicesView.vue';
import SettingsImagesView from '@/views/Settings/SettingsImagesView.vue';
import CamerasView from '@/views/Cameras/CamerasView.vue';
import { LelyAuth } from '@tec/frontend-vue-shared';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        component: LoginView,
        name: 'login',
        meta: {
            guest: true,
        },
    },
    {
        path: '/',
        name: 'vehicles',
        component: VehiclesView,
    },
    {
        path: '/cameras',
        name: 'cameras',
        component: CamerasView,
    },
    {
        path: '/settings',
        name: 'settings',
        component: SettingsView,
        redirect: () => ({
            name: 'settings.services',
        }),
        children: [
            {
                path: 'services',
                name: 'settings.services',
                component: SettingsServicesView,
            },
            {
                path: 'images',
                name: 'settings.images',
                component: SettingsImagesView,
            },
        ],
    },
];

const router = new VueRouter({
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.guest)) {
        if (!LelyAuth.isAuthenticated()) {
            return next();
        }
        return next({ name: 'vehicles' });
    }

    if (LelyAuth.isAuthenticated()) {
        return next();
    }

    return next({ name: 'login' });
});

export default router;
