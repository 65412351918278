























































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import UpdateModalServicesRow from '@/components/UpdateModalServicesRow.vue';
import { ServiceData } from '@/components/UpdateModal.vue';

@Component({
    components: { UpdateModalServicesRow },
})
export default class UpdateModal extends Vue {
    @Prop({ required: true })
    serviceData!: ServiceData[];

    @Action('syncTags')
    syncTagsAction!: (imageId: number) => Promise<void>;

    get anyServiceEnabled(): boolean {
        return this.serviceData.some(data => data.enabled);
    }

    get valid(): boolean {
        return !this.serviceData.some(data => data.enabled && data.image && (!data.tag || !data.runTemplate || !data.buildTemplate));
    }

    syncTags(imageId: number): void {
        if (imageId) {
            this.syncTagsAction(imageId);
        }
    }
}

