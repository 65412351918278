































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Image from '@/interfaces/Image';
import { Action, Getter } from 'vuex-class';
import Tag from '@/interfaces/Tag';
import { format, parseISO } from 'date-fns';

@Component({
    components: {},
})
export default class SettingsImageRow extends Vue {
    @Prop({ required: true })
    image!: Image;

    @Action('deleteImage')
    deleteImageAction!: (id: number) => Promise<void>;

    @Action('deleteTag')
    deleteTagAction!: (tag: Tag) => Promise<void>;

    @Getter('tagsByImageId')
    tagsByImageId!: (id: number) => Tag[];

    @Action('syncTags')
    syncTags!: (imageId: number) => Promise<void>;

    get tags(): Tag[] {
        return this.tagsByImageId(this.image.id);
    }

    async deleteImage(): Promise<void> {
        const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the image "${this.image.name}"?`, {
            title: 'Delete image',
            size: 'sm',
        });

        if (!confirm) {
            return;
        }

        await this.deleteImageAction(this.image.id);
    }

    async deleteTag(tag: Tag): Promise<void> {
        const confirm = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete the tag "${tag.name}"?`, {
            title: 'Delete tag',
            size: 'sm',
        });

        if (!confirm) {
            return;
        }

        await this.deleteTagAction(tag);
    }

    async showTags(): Promise<void> {
        await this.syncTags(this.image.id);

        this.$bvModal.show(`tags-modal-${this.image.id}`);
    }

    formatDate(date: string): string {
        return format(parseISO(date), 'dd-MM-yyyy HH:mm');
    }
}

