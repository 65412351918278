import Vehicle from '@/interfaces/Vehicle';
import RootState from '@/store/rootState';
import VehicleType from '@/interfaces/VehicleType';
import Service from '@/interfaces/Service';
import Image from '@/interfaces/Image';
import Version from '@/interfaces/Version';
import Tag from '@/interfaces/Tag';
import SchedulePlanned from '@/interfaces/SchedulePlanned';

export const vehicleTypes = (state: RootState): VehicleType[] => state.vehicleTypes.sort((a, b) => a
    .name.localeCompare(b.name));

export const images = (state: RootState): Image[] => state.images.sort((a, b) => a
    .name.localeCompare(b.name));

export const services = (state: RootState): Service[] => state.services.sort((a, b) => a
    .name.localeCompare(b.name));

export const cameras = (state: RootState): string[] => state.cameras.sort((a, b) => a
    .localeCompare(b));

export const tags = (state: RootState): Tag[] => state.tags;

export const versionsByServiceId = (state: RootState) => (serviceId: number): Version[] => state.versions
    .filter(version => version.service_id === serviceId);

export const tagsByImageId = (state: RootState, getters: any) => (imageId: number): Tag[] => getters.tags
    .filter((tag: Tag) => tag.image_id === imageId);

export const selectedVehicleTypeVehicles = (state: RootState): Vehicle[] => state.vehicles
    .filter(vehicle => vehicle.vehicle_type_id === state.selectedVehicleTypeId)
    .sort((a, b) => a.name.localeCompare(b.name));

export const selectedVehicleTypeServices = (state: RootState, getters: any): Service[] => getters.services
    .filter((service: Service) => service.vehicle_type_id === state.selectedVehicleTypeId);

export const selectedVehicleTypeSchedules = (state: RootState, getters: any): SchedulePlanned[] => {
    const vehicleIds = getters.selectedVehicleTypeVehicles.map((vehicle: Vehicle) => vehicle.id);

    return state.schedules.filter(schedule => vehicleIds.includes(schedule.vehicle_id));
};

export const vehicleTypeById = (state: RootState) => (id: number): VehicleType => state.vehicleTypes.find(vehicleType => vehicleType.id === id) as VehicleType;

export const tagById = (state: RootState) => (id: number): Tag | undefined => state.tags.find(tag => tag.id === id);
export const imageById = (state: RootState) => (id: number): Image | undefined => state.images.find(image => image.id === id);
export const templateContentById = (state: RootState) => (id: number): string | undefined => state.templateContent[id];
